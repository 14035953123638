<template>
  <div>
    <Datatable :perPage="10" :rows="[]" :columns="columns" />
  </div>

  <div class="container">
    <div class="row">
      <div class="col-md-4 order-md-2 mb-4">
        <h4 class="d-flex justify-content-between align-items-center mb-3">
          <span class="text-muted">Resumé de votre payment</span>
        </h4>
        <ul class="list-group mb-3">
          <li
            class="list-group-item d-flex justify-content-between lh-condensed"
          >
            <div>
              <h6 class="my-0">Cours</h6>
              <small class="text-muted">Brief description</small>
            </div>
          </li>
          <li
            class="list-group-item d-flex justify-content-between lh-condensed"
          >
            <div>
              <h6 class="my-0">Enseignant</h6>
              <small class="text-muted">Brief description</small>
            </div>
          </li>
          <li
            class="list-group-item d-flex justify-content-between lh-condensed"
          >
            <div>
              <h6 class="my-0">Montant</h6>
              <strong class="text-muted">15000 XAF</strong>
            </div>
          </li>
          <li
            class="list-group-item d-flex justify-content-between lh-condensed"
          >
            <div>
              <h6 class="my-0">Devise</h6>
              <strong class="text-muted">XAF</strong>
            </div>
          </li>
          <li class="list-group-item d-flex justify-content-between bg-light">
            <div class="text-success">
              <h6 class="my-0">Promo code</h6>
              <small>EXAMPLECODE</small>
            </div>
            <span class="text-success">-5 XAF</span>
          </li>
          <li class="list-group-item d-flex justify-content-between">
            <span>Total </span>
            <strong>20</strong>
          </li>
        </ul>

        <form class="card p-2">
          <div class="input-group">
            <input type="text" class="form-control" placeholder="Promo code" />
            <div class="input-group-append">
              <button type="submit" class="btn btn-secondary">Appliquer</button>
            </div>
          </div>
        </form>
      </div>
      <div class="col-md-8 order-md-1">
        <h4 class="mb-3">Payment</h4>

        <div class="d-block my-3">
          <div class="custom-control custom-radio">
            <input
              id="credit"
              name="paymentMethod"
              type="radio"
              class="custom-control-input"
              checked=""
              required=""
            />
            <label class="custom-control-label" for="credit"
              >Mobile Money</label
            >
          </div>
          <div class="custom-control custom-radio">
            <input
              id="debit"
              name="paymentMethod"
              type="radio"
              class="custom-control-input"
              required=""
            />
            <label class="custom-control-label" for="debit"
              >Visa/Mastercard</label
            >
          </div>
        </div>

        <hr class="mb-4" />
        <h4 class="mb-3">Details de facturation</h4>
        <form class="needs-validation" novalidate="">
          <div class="row">
            <div class="col-md-5 mb-3">
              <label for="adresse">Adresse</label>
              <input
                type="text"
                class="form-control"
                id="adresse"
                placeholder=""
                required=""
              />
              <div class="invalid-feedback">
                Zip code required.
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="telephone">Telephone</label>
              <input
                type="tel"
                class="form-control"
                id="telephone"
                placeholder=""
                required=""
              />
              <div class="invalid-feedback">
                Zip code required.
              </div>
            </div>
            <div class="col-md-3 mb-3">
              <label for="ville">Ville</label>
              <input
                type="text"
                class="form-control"
                id="ville"
                placeholder=""
                required=""
              />
              <div class="invalid-feedback">
                Zip code required.
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-5 mb-3">
              <label for="country">Country</label>
              <select
                class="custom-select d-block w-100"
                id="country"
                required=""
              >
                <option value="">Choose...</option>
                <option>United States</option>
              </select>
              <div class="invalid-feedback">
                Please select a valid country.
              </div>
            </div>
            <div class="col-md-4 mb-3">
              <label for="state">State</label>
              <select
                class="custom-select d-block w-100"
                id="state"
                required=""
              >
                <option value="">Choose...</option>
                <option>California</option>
              </select>
              <div class="invalid-feedback">
                Please provide a valid state.
              </div>
            </div>
            <div class="col-md-3 mb-3">
              <label for="zip">Zip</label>
              <input
                type="text"
                class="form-control"
                id="zip"
                placeholder=""
                required=""
              />
              <div class="invalid-feedback">
                Zip code required.
              </div>
            </div>
          </div>

          <hr class="mb-4" />
          <button class="btn btn-primary btn-lg btn-block" type="submit">
            Proceder au paiement
          </button>
        </form>
      </div>
    </div>
  </div>
  <div class="skeleton h-50"></div>
</template>
<script>
import Datatable from "../components/shared/tools/Datatable.vue";
export default {
  components: { Datatable },
  data() {
    return {
      columns: [
        {
          property: "firstName",
          title: "First Name",
          direction: "up",
          filterable: true,
        },
        {
          property: "lastName",
          title: "Last Name",
          direction: "up",
          filterable: true,
          collapseIcon: true,
        },
        {
          property: "test",
          title: "test",
          direction: null,
          filterable: false,
          collapseIcon: true,
        },
      ],
      rows: [
        {
          firstName: "Marie-Esmeralda",
          lastName: "Leopoldine",
        },
        {
          firstName: "Alexander",
          lastName: "Van Belgie",
        },
        {
          firstName: "Marie-Christine",
          lastName: "Leopoldine",
        },
        {
          firstName: "Marie-Esmeralda",
          lastName: "Leopoldine",
        },
      ],
    };
  },
};
</script>
<style lang="css" scoped>
.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s infinite alternate;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
</style>
